import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'
import { getPromotionListAPI, getIsActivatePromotionAPI, activatePromotionAPI, cancelPromotionAPI, addPromotionAPI, deletePromotionAPI } from 'api/promotion'
import { toNumberFormat } from 'utils/toNumberFormat';
import useUserInfoUpdate from 'hooks/useUserInfoUpdate'
import Dialog from '@mui/material/Dialog';
import ImgFirstDeposit from 'assets/Content/promotion/first-deposit.jpg'
import ImgRedeemCode from 'assets/Content/promotion/redeem-code.jpg'
import ImgRedeemBonus from 'assets/Content/promotion/redeem-bonus.jpg'
import ImgDailyWins from 'assets/Content/banner/daily-wins.jpg'
import ImgSweetBonanza from 'assets/Content/banner/sweet-bonanza.jpg'
import BaseButton from 'components/ui/button/BaseButton';
import IconButton from 'components/ui/button/IconButton';

import FormInput from 'components/ui/input/FormInput';
import Select from 'components/ui/select/Select'
import CheckBox from 'components/ui/CheckBox';


function TempImage() {
  return (
    <div className="tempImage-wrapper">
      <div className="tempImage"></div>
    </div>
  )
}


function PromotionsAdmin({refashList, data}) {
  const userInfo = useSelector(state => { return state?.user?.userInfo; });
  const [addLoading, setAddLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteEmail, setDeleteEmail] = useState(userInfo?.email);
  const [addEmail, setAddEmail] = useState(userInfo?.email);
  const [addTitle, setAddTitle] = useState(null);
  const [deleteTitle, setDeleteTitle] = useState(null);
  const [amount, setAmount] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [currencyList, setCurrencyList] = useState(null);
  const titleList = data.map(item => item.title);
  const addTitleSelectData = { list: titleList, id: "add-promotion-title-select", state: addTitle, setState: setAddTitle, label: "Add Title select" };
  const deleteTitleSelectData = { list: titleList, id: "delete-promotion-title-select", state: deleteTitle, setState: setDeleteTitle, label: "Delete Title select" };
  const currencySelectData = { list: currencyList, id: "add-promotion-currency-select", state: currency, setState: setCurrency, label: "Currency select" };


  useEffect(() => {
    const currencyList = [];
    Object.values(userInfo?.currency).forEach((item) => {
      currencyList.push(item.symbol)
    })
    setCurrencyList(currencyList);
  }, [])

  const addEmailInput = {
    id: 'add-promotion-email',
    placeholder: 'Email',
    label: 'Email Value',
    value: addEmail,
    setValue: setAddEmail
  }
  const amountInput = {
    id: 'add-promotion-amount',
    placeholder: 'Amount',
    label: 'Amount Value',
    value: amount,
    setValue: setAmount
  }
  const deleteEmailInput = {
    id: 'delete-promotion-email',
    placeholder: 'Email',
    label: 'Email Value',
    value: deleteEmail,
    setValue: setDeleteEmail
  }


  const addPromotion = async () => {
    setAddLoading(true)
    try {
      await addPromotionAPI(addEmail, addTitle, currency, amount)
      setAmount(null)
      refashList()
      alert("add complete")
    }
    catch(e) {
      console.error("e: ", e)
    }
    finally {
      setAddLoading(false)
    }
  }

  const deletePromotion = async () => {
    setDeleteLoading(true)
    try {
      await deletePromotionAPI(deleteEmail, deleteTitle)
      refashList()
      alert("delete complete")
    }
    catch(e) {
      console.error("e: ", e)
    }
    finally {
      setDeleteLoading(false)
    }
  }

  return (
    <div className="promotion-test-wrapper">
      <div className="box">
        <h1>Add Promotion</h1>
        <div className="box__inner">
          <FormInput labelSize="small" {...addEmailInput} />
          <Select {...addTitleSelectData} notation="none" />
          <Select {...currencySelectData} notation="none" />
          <FormInput labelSize="small" {...amountInput} />
        </div>
        <div className="box__foot">
          <BaseButton isLoading={addLoading} onClick={() => addPromotion()} color="primary" label="Add" size="large" />
        </div>
      </div>
      <div className="box">
        <h1>Delete Promotion</h1>
        <div className="box__inner">
          <FormInput labelSize="small" {...deleteEmailInput} />
          <Select {...deleteTitleSelectData} notation="none" />
        </div>
        <div className="box__foot">
          <BaseButton isLoading={deleteLoading} onClick={() => deletePromotion()} color="primary" label="Delete" size="large" />
        </div>
      </div>
    </div>
  )
}

function calculateProgress(targetAmount, currentAmount) {
  if (currentAmount < 0 || targetAmount <= 0) {
      return 0;
  }
  const percentage = (currentAmount / targetAmount) * 100;
  return Math.min(100, Math.floor(percentage));
}

function FirstDepositAlertModal({ open, handleClose, data }) {
  const { t } = useTranslation();
  const { isFunction, isActive, openActiveModal } = data;
  const [checked, setChecked] = useState(false);
  const checkBoxInfo = {
    label: 'component.modal.promotionFirstDepositAlert.checkInfo',
    isRequired: true,
    checked,
    setChecked
  }

  const activePromotion = () => {
    if (isActive) {
      openActiveModal();
    } else {
      isFunction();
    }
    close()
  }

  const close = () => {
    handleClose()
    setChecked(false);
  }

  return (
    <Dialog
      open={open}
      onClose={close}
      className="base-dialog-wrapper content-dialog-wrapper small"
    >
      <div className="base-dialog-inner bottom-p-32">
        <div className="base-dialog-header">
          <p className="base-dialog-header__title medium">{t('component.modal.promotionFirstDepositAlert.title')}</p>
          <button onClick={close} className="close-btn"></button>
        </div>
        <div className="base-dialog-body campaign">
          <div className="alert-info">
            <div className="alert-info__inner">
              <div className="info-desc">
                <p>{t('component.modal.promotionFirstDepositAlert.info.1')}</p>
                <p>{t('component.modal.promotionFirstDepositAlert.info.2')}</p>
                <p>{t('component.modal.promotionFirstDepositAlert.info.3')}</p>
                <span onClick={() => { window.open("https://medium.com/@ACECASINO/ace-casino-%EF%B8%8F-first-deposit-bonus-2024-5cff7baa2621") }}>
                {t('component.modal.promotionFirstDepositAlert.moreDetail')}
                </span>
              </div>
              <CheckBox {...checkBoxInfo} />
            </div>
            <BaseButton type="submit" color="primary" label="component.modal.promotionFirstDepositAlert.btn"
            size="large" isDisabled={!checked} onClick={() => activePromotion()} />
          </div>
        </div>
      </div>
    </Dialog>
  );
}

function ActivePromotionModal({ open, handleClose, data }) {
  const { t } = useTranslation();
  const { activeSymbol, isFunction } = data;
  const userBalance = useSelector(state => { return state?.user?.userInfo?.balance; });
  const activeBouns = userBalance.filter((item) => item.symbol === activeSymbol)?.[0]?.bonus;

  const activePromotion = () => {
    isFunction();
    handleClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="base-dialog-wrapper content-dialog-wrapper small"
    >
      <div className="base-dialog-inner">
        <div className="base-dialog-header">
          <p className="base-dialog-header__title medium caution">{t("component.modal.promotionCaution.title")}</p>
          <button onClick={handleClose} className="close-btn"></button>
        </div>
        <div className="base-dialog-body promotion">
          <div className="info">
            <ul className="info__inner">
              <li>{t("component.modal.promotionCaution.activateInfo.1")}</li>
              <li>{t("component.modal.promotionCaution.activateInfo.2", { amount: `$${toNumberFormat(activeBouns, 2)}` })}</li>
            </ul>
            <div className="info__action">
              <BaseButton onClick={() => activePromotion()} color="danger" label="commonDesc.confirm" size="large" />
              <BaseButton onClick={() => handleClose()} color="gray" label="commonDesc.cancel" size="large" />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

function CancelPromotionModal({ open, handleClose, data }) {
  const { t } = useTranslation();
  const { activeSymbol, isFunction } = data;
  const userBalance = useSelector(state => { return state?.user?.userInfo?.balance; });
  const activeBouns = userBalance.filter((item) => item.symbol === activeSymbol)?.[0]?.bonus;

  const cancelPromotion = () => {
    isFunction();
    handleClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="base-dialog-wrapper content-dialog-wrapper small"
    >
      <div className="base-dialog-inner">
        <div className="base-dialog-header">
          <p className="base-dialog-header__title medium caution">{t("component.modal.promotionCaution.title")}</p>
          <button onClick={handleClose} className="close-btn"></button>
        </div>
        <div className="base-dialog-body promotion">
          <div className="info">
            <ul className="info__inner">
              <li>{t("component.modal.promotionCaution.cancelInfo.1")}</li>
              <li>{t("component.modal.promotionCaution.cancelInfo.2", { amount: `$${toNumberFormat(activeBouns, 2)}` })}</li>
            </ul>
            <div className="info__action">
              <BaseButton onClick={() => cancelPromotion()} color="danger" label="commonDesc.yes" size="large" />
              <BaseButton onClick={() => handleClose()} color="gray" label="commonDesc.no" size="large" />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

function PromotionsItems({data}) {
  // const [isMore, setIsMore] = useState(false);
  // const isMobile = useSelector(state => {
  //   return state?.ui?.screen.viewType.mobile;
  // });
  // const toggleIsMore = () => {
  //   setIsMore(!isMore);
  // };

  // const sortedData = data ? [
  //   ...data.filter(item => item.id === 34),
  //   ...data.filter(item => item.id === 67),
  //   ...data.filter(item => item.id !== 34 && item.id !== 67),
  // ] : [];

  return (
    <div className="promotions-inner__items">
      <div className="promotions-items">
        {data?.length ? data.map((item, index) => {
          return <div className="promotions-item" onClick={() => { window.open(item.link) }} key={index}>
            <div className="thumbnail">
              {item.id === 1 ? <img src={ImgFirstDeposit} alt="First Deposit bonus cover" /> :
              item.id === 34 ? <img src={ImgRedeemBonus} alt="redeem bonus cover" /> :
              item.id === 67 ? <img src={ImgDailyWins} alt="daily wins cover" /> :
              item.id === 68 ? <img src={ImgSweetBonanza} alt="sweet bonanza cover" /> :
              item.id === 69 ? <img src={ImgRedeemCode} alt="redeem code cover" /> :
              <TempImage />}
            </div>
            <div className="info">
              <p className="info-title">{item?.title ? item.title : "-"}</p>
              {new Date(item.end_date).getFullYear() === 2099 ? <span className="info-date">REGULARS</span> :
              <span className="info-date">{`${new Date(item.start_date).toISOString().replace(/T|Z/g," ").slice(0,16)}~${new Date(item.end_date).toISOString().replace(/T|Z/g," ").slice(0,16)}`}</span>}
            </div>
          </div>
        }) : null}
      </div>
      {/* {isMobile &&
      <div className="promotions-items-footer">
        <p onClick={toggleIsMore} className={`more-btn ${isMore ? 'open' : ''}`}>{t('commonDesc.more')}</p>
      </div>} */}
    </div>
  )
}

const tableHead = [
  "pages.promotions.tableHead.date",
  "pages.promotions.tableHead.promotion",
  "pages.promotions.tableHead.depositAmount",
  "pages.promotions.tableHead.cryptoCurrency",
  "pages.promotions.tableHead.bonusAmount",
  "pages.promotions.tableHead.wagerCondition",
  "pages.promotions.tableHead.wagered",
  "pages.promotions.tableHead.timeLimit",
  ""
]

function RowCountDown({data}) {
  const [countdown, setCountdown] = useState("-");

  const getFormattedTime = (date) => {
    const second = 1000;
    const minute = second * 60;
    const hour = minute * 60;
    const day = hour * 24;

    let days = Math.floor(date / day);
    let hours = Math.floor((date % day) / hour);
    let minutes = Math.floor((date % hour) / minute);
    let seconds = Math.floor((date % minute) / second);

    days = days > 0 ? days : 0;
    hours = hours > 0 ? hours : 0;
    minutes = minutes > 0 ? minutes : 0;
    seconds = seconds > 0 ? seconds : 0;

    return `${ days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's' } left`;
  }

  useEffect(() => {
    if (!data?.activation_date && !data?.expired_date) return;
    const intervalId = setInterval(() => {
      const currentTime = new Date().getTime();
      const expiredTime = new Date(data.expired_date).getTime();
      const remainingTime = expiredTime - currentTime;

      if (remainingTime <= 0) {
        clearInterval(intervalId);
        setCountdown("-");
      } else {
        setCountdown(getFormattedTime(remainingTime));
      }
    }, 1000);

    return () => {
      clearInterval(intervalId)
    };
  }, [data]);


  if (!data?.activation_date && !data?.expired_date) {
    return null;
  }
  return (
    <>{countdown}</>
  )
}

function PromotionsList({data, refashList}) {
  const { t } = useTranslation();
  const userEmail = useSelector(state => { return state?.user?.userInfo.email; });
  const [isActive, setIsActive] = useState(false);
  const [modalData, setModalData] = useState({});
  const [cancelOpen, setCancelOpen] = useState(false);
  const [activeOpen, setActiveOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [activeSymbol, setActiveSymbol] = useState("");
  /*
    *status type*
    completed - 완료된
    pending_activation - 초기상태
    active - 활성화 후 진행중
    cancelled - 취소된
    expired - 기간내에 달성하지 못하고 만료된
  */
  const isActiveCheck = (title, id) => {
    if (id === 1) {
      alertModalOpen(title);
      return;
    }
    if (isActive) {
      activeModalOpen(title)
    } else {
      activatePromotion(title)
    }
  }

  const alertModalOpen = (title) => {
    setModalData({
      isFunction: () => {
        activatePromotion(title)
      },
      isActive,
      openActiveModal: () => {
        activeModalOpen(title)
      },
    })
    setAlertOpen(true);
  };
  const alertModalClose = () => {
    setAlertOpen(false);
  };
  const activeModalOpen = (title) => {
    setModalData({
      isFunction: () => {
        activatePromotion(title)
      },
      activeSymbol
    })
    setActiveOpen(true);
  };
  const activeModalClose = () => {
    setActiveOpen(false);
  };
  const cancelModalOpen = (title) => {
    setModalData({
      isFunction: () => {
        cancelPromotion(title)
      },
      activeSymbol
    })
    setCancelOpen(true);
  };
  const cancelModalClose = () => {
    setCancelOpen(false);
  };

  const cancelPromotion = async (title) => {
    try {
      await cancelPromotionAPI(userEmail, title);
      refashList()
    }
    catch(e) {
      console.error("cancelPromotion e: ", e)
    }
  }

  const activatePromotion = async (title) => { // 활성화
    if (!title) return;
    try {
      await activatePromotionAPI(userEmail, title);
      refashList()
    }
    catch(e) {
      console.error("activatePromotion e: ", e)
    }
  }

  const getIsActivatePromotion = async () => { // 활성화 유무
    try {
      const res = await getIsActivatePromotionAPI(userEmail);
      setIsActive(res);
      const symbol = data?.filter(item => item.status === "active")?.[0]?.symbol
      setActiveSymbol(symbol)
    }
    catch(e) {
      console.error("isActivatePromotion e: ", e)
    }
  }

  useEffect(() => {
    getIsActivatePromotion()
  }, [data])

  return (
    <>
      <div className="table-section-wrapper section-wrapper promotions-list">
        <div className="table-section-inner">
          <p className="warring-msg">The maximum amount of payout received from winnings by betting bonus shall not exceed 10 times the deposit including its bonus amount.</p>
          <div className="table-container promotions">
            <table className="table-inner">
              <colgroup>
                <col className="date" />
                <col className="title" />
                <col className="depositAmount" />
                <col className="currency" />
                <col className="bonusAmount" />
                <col className="wagerCondition" />
                <col className="wagered" />
                <col className="time" />
                <col className="action" />
              </colgroup>
              <thead className="table-inner__head">
                <tr>
                {tableHead.map((head, index) => {
                  return <th key={index} className={head}
                  dangerouslySetInnerHTML={{ __html: t(head) }}></th>
                })}
                </tr>
              </thead>
              <tbody className="table-inner__body">
                  {data?.length ? data.map((body, index) => {
                    if (body.status === null) return null;
                    return <tr key={index}>
                      <td className="date"><p>{body.activation_date ? new Date(body.activation_date).toISOString().replace(/T|Z/g," ").slice(0,16) : "-"}</p></td>
                      <td><p>{body?.title ? body.title : "-"}</p></td>
                      <td><p>{body?.initial_amount ? `$${toNumberFormat(body.initial_amount, 2)}` : "-"}</p></td>
                      <td><p>{body?.symbol ? body.symbol : "-"}</p></td>
                      <td><p>{body?.bonus_amount ? `$${toNumberFormat(body.bonus_amount, 2)}` : "-"}</p></td>
                      <td><p>{body?.rollover_requirement ? `$${toNumberFormat(body.rollover_requirement, 2)}` : "-"}</p></td>
                      <td><p>{body?.rollover_requirement && body?.current_rollover_amount ?
                      `$${toNumberFormat(body.current_rollover_amount, 2)}(${calculateProgress(body.rollover_requirement, body.current_rollover_amount)}%)` : "-"}
                      </p></td>
                      <td><p>{body?.status === "active" ? <RowCountDown data={body} /> : "-"}</p></td>
                      <td>{
                        body?.status === "active" ?
                          <div className="double">
                            <IconButton iconName="verified" label="pages.promotions.btnText.active" />
                            <BaseButton isDisabled={body.status !== "active"} onClick={() => cancelModalOpen(body.title)} size="small" color="gray" label="commonDesc.cancel" />
                          </div> :
                        body?.status === "expired" ?
                          <div className="solo">
                            <BaseButton isDisabled={true} size="small" color="gray" label="pages.promotions.btnText.expired" />
                          </div> :
                          body?.status === "completed" ?
                          <div className="solo">
                            <p className="complete">{t("pages.promotions.btnText.complete")}</p>
                          </div> :
                          body?.status === "cancelled" ?
                          <div className="solo">
                            <p className="canceled">{t("pages.promotions.btnText.canceled")}</p>
                          </div> :
                          <div className="double">
                            <BaseButton onClick={() => isActiveCheck(body.title, body.id)} size="small" color="primary" label="pages.promotions.btnText.activate" />
                            <BaseButton isDisabled={true} size="small" color="gray" label="commonDesc.cancel" />
                          </div>}
                      </td>
                    </tr>
                  }) : null}
              </tbody>
            </table>
            {!data?.length ? <div className="not-list">
                <p>{t('pages.history.notList')}</p>
              </div> : null}
          </div>
        </div>
      </div>
      <CancelPromotionModal open={cancelOpen} handleClose={cancelModalClose} data={modalData} />
      <ActivePromotionModal open={activeOpen} handleClose={activeModalClose} data={modalData} />
      <FirstDepositAlertModal open={alertOpen} handleClose={alertModalClose} data={modalData} />
    </>
  )
}

export default function Promotions() {
  const { t } = useTranslation();
  const userEmail = useSelector(state => { return state?.user?.userInfo.email; });
  const [data, setData] = useState([]);
  const { updateUserBalance } = useUserInfoUpdate();

  const getPromotionList = async () => {
    try {
      const res = await getPromotionListAPI(userEmail);
      // console.log("res: ", res)
      setData(res)
      updateUserBalance()
    }
    catch(e) {
      console.error("getPromotionList e: ", e)
    }
  }

  useEffect(() => {
    getPromotionList()
  }, [])

  return (
    <div className="promotions-wrapper section-wrapper">
      <div className="section-wrapper__header">
        <p className="promotions">{t('pages.promotions.title')}</p>
      </div>
      <div className="promotions-inner">
        {window.location.hostname !== "www.acecasino.io" ? <PromotionsAdmin data={data} refashList={getPromotionList} /> : null}
        <PromotionsItems data={data} />
        <PromotionsList data={data} refashList={getPromotionList} />
      </div>
    </div>
  )
}