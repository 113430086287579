import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createCampaignAPI, checkCampaignAPI, getClaimableInfoAPI, getCampaignHitInfoAPI, claimCampaignAPI } from 'api/affilate';
import { getUserInfoAPI } from 'api/user';
import { setUserInfo } from 'store/modules/user';
import { useSnackbar } from "notistack";
import CopyItem from 'components/ui/CopyItem';
import BaseButton from 'components/ui/button/BaseButton';
import Dialog from '@mui/material/Dialog';
import FormInput from 'components/ui/input/FormInput';
import CheckBox from 'components/ui/CheckBox';
import NoListLogo from 'assets/Logo/logo_no_list.png';
import { toNumberFormat } from 'utils/toNumberFormat';
import SymbolImg from 'components/item/SymbolImg';


function setRefLink(campaignCode) {
  const url = window.location.hostname === "www.acecasino.io" ? 'acecasino.io/en/campaign/' : 'test.acecasino.io/en/campaign/'
  const result = `${url}${campaignCode}`
  return result;
}

function DeleteCampaignModal({ open, handleClose }) {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const checkBoxInfo = {
    label: 'component.modal.deleteCampaign.checkLabel',
    isRequired: true,
    checked,
    setChecked
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="base-dialog-wrapper content-dialog-wrapper small"
    >
      <div className="base-dialog-inner bottom-p-32">
        <div className="base-dialog-header">
          <p className="base-dialog-header__title medium">{t('component.modal.deleteCampaign.title')}</p>
          <button onClick={handleClose} className="close-btn"></button>
        </div>
        <div className="base-dialog-body campaign">
          <div className="delete-info">
            <div className="delete-info__inner">
              <p className="info-desc">{t('component.modal.deleteCampaign.info')}</p>
              <CheckBox {...checkBoxInfo} />
            </div>
            <BaseButton type="submit" color="danger" label="component.modal.deleteCampaign.deleteBtn" size="large" isDisabled={!checked} />
          </div>
        </div>
      </div>
    </Dialog>
  );
}

function validateString(inputText) {
  const regex = /^[a-zA-Z0-9\s]+$/
  return regex.test(inputText);
}

function removeSpaces(sentence) {
  return sentence?.replace(/\s/g, '');
}


function CreateCampaignModal({ open, handleClose, getList }) {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const userEmail = useSelector(state => { return state?.user?.userInfo.email; });
  const isMobile = useSelector(state => { return state?.ui?.screen.viewType.mobile; });
  const [name, setName] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [refCode, setRefCode] = useState(null);
  const [refCodeError, setRefCodeError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setName(null)
    setRefCode(null)
    setNameError(null)
    setRefCodeError(null)
  }, [handleClose])

  const campaignNameInput = {
    id: 'campaign-name',
    placeholder: 'component.modal.createCampaign.name.placeholder',
    label: 'component.modal.createCampaign.name.label',
    value: name,
    setValue: setName,
    isError: nameError || ((name?.length >= 1 && !validateString(name)) ? "Please enter in English using uppercase and lowercase letters only." : null), // 영문의 대소문자로만 입력해주세요
    handleFocus: () => {
      setNameError(null);
    },
    handleBlur: async () => {
      if (!name?.length || !validateString(name)) return;
      if (name?.length <= 2) {
        setNameError("Please enter a minimum of 3 characters.") // 최소 3자 이상 입력해 주세요
        return;
      }
      const cName = removeSpaces(name);
      const check = await checkNameRef(cName, null);
      if (check.name_count-0) {
        setNameError("Duplicate campaign name.") // 중복된 캠페인 이름입니다
        return;
      }
      setName(cName);
    }
  }

  const campaignLinkInput = {
    id: 'campaign-link',
    label: 'component.modal.createCampaign.link.label',
    value: refCode,
    setValue: setRefCode,
    isError: refCodeError || ((refCode?.length >= 1 && !validateString(refCode)) ? "Please enter in English using uppercase and lowercase letters only." : null), // 영문의 대소문자로만 입력해주세요
    isDesc: isMobile ? "/campaign?code=" : "acecasino.io/en/campaign?code=",
    handleFocus: () => {
      setRefCodeError(null);
    },
    handleBlur: async () => {
      if (!refCode?.length || !validateString(refCode)) return;
      if (refCode?.length <= 2) {
        setRefCodeError("Please enter a minimum of 3 characters.") // 최소 3자 이상 입력해 주세요
        return;
      }
      const code = removeSpaces(refCode);
      const check = await checkNameRef(null, code);
      if (check.code_count-0) {
        setRefCodeError("Duplicate referral code.") // 중복된 레퍼럴 코드입니다
        return;
      }
      setRefCode(code);
    }
  }

  const checkNameRef = async (cName, code) => {
    try {
      const res = await checkCampaignAPI(userEmail, cName, code);
      return res;
    } catch (e) {
      console.log('checkEmail e:', e);
    }
  }

  const createCampaign = async () => {
    setLoading(true)
    await createCampaignAPI(userEmail, name, refCode)
    .then(() => {
      getList();
      handleClose();
      enqueueSnackbar({ msg: "snackbarTitle.success", variant: "success", action: () => {closeSnackbar()} })
      setLoading(false)
    })
    .catch((e) => {
      enqueueSnackbar({ msg: e.message, variant: "error", action: () => {closeSnackbar()} })
      console.log("createCampaign e: ", e)
      setLoading(false)
    })
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="base-dialog-wrapper content-dialog-wrapper small"
    >
      <div className="base-dialog-inner bottom-p-32">
        <div className="base-dialog-header">
          <p className="base-dialog-header__title medium">{t('component.modal.createCampaign.title')}</p>
          <button onClick={handleClose} className="close-btn"></button>
        </div>
        <div className="base-dialog-body campaign">
          <div className="campaign-form">
            <div className="campaign-form__inner">
              <FormInput {...campaignNameInput} />
              <FormInput {...campaignLinkInput} />
            </div>
            <BaseButton isLoading={loading}
            isDisabled={name?.length < 3 || refCode?.length < 3 || !name || !refCode || !validateString(name) || !validateString(refCode) || nameError !== null || refCodeError !== null} onClick={createCampaign} color="primary" label="component.modal.createCampaign.createBtn" size="large" />
          </div>
        </div>
      </div>
    </Dialog>
  );
}

const detailTableHead = [
  "pages.affiliate.referredUsers.tableBody.cryptos",
  "pages.affiliate.referredUsers.tableBody.date",
  "pages.affiliate.referredUsers.tableBody.claimAmount",
  "pages.affiliate.referredUsers.tableBody.rolloverAmount",
  "pages.affiliate.referredUsers.tableBody.claimable",
  "pages.affiliate.referredUsers.tableBody.claimed",
]

const AccordionItem = (props) => {
  const { t } = useTranslation();
  const contentEl = useRef();
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const userEmail = useSelector(state => { return state?.user?.userInfo.email; });

  const { toggle, active, item, index, rewardRatio } = props;
  const { campaign_name, campaign_code, wagered, commission, deposit_sum } = item;
  const [innerData, setInnerData] = useState({});
  const { total, list: innerList, isClaim } = innerData;

  const getData = async () => {
    /**
     * inner data api call
     * claimInfo
     *  - claimable: true이면 클래임 버튼을 활성화
     *  - claimInfo: accordion detail list data
     */
    Promise.all([
      getClaimableInfoAPI(userEmail, campaign_name),
      getCampaignHitInfoAPI(userEmail, campaign_name)
    ]).then((res) => {
      const [claimInfo, hitCount] = res;
      const data = {
        total: {
          campaignHits: hitCount.toString(),
          referrals: hitCount.toString(),
          comissionRate: rewardRatio,
        },
        list : claimInfo?.claimInfo ?? [],
        isClaim: claimInfo.claimable
      }
      setInnerData(data)
    })
  }
  // const [deleteOpen, setDeleteOpen] = useState(false);

  // const deleteModalOpen = () => {
  //   setDeleteOpen(true);
  // };
  // const deleteModalClose = () => {
  //   setDeleteOpen(false);
  // };

  useEffect(() => {
    if (active !== null && active === index) {
      getData()
    }
  }, [active])

  const claim = async () => {
    if (!userEmail) return;
    try {
      await claimCampaignAPI(userEmail, campaign_name)
      enqueueSnackbar({ msg: "snackbarTitle.success", variant: "success", action: () => {closeSnackbar()} })
      getData(item)
      const res = await getUserInfoAPI(userEmail);
      dispatch(setUserInfo(res));
    } catch(e) {
      console.log("addSubscribedAPI e: ", e)
    }
  }

  return (
    <>
      <div className="accordion-item">
        <ul className={`accordion-item__head`} onClick={() => toggle(index)}>
          <li className="name"><p>{campaign_name}</p></li>
          <li className="link"><p>{setRefLink(campaign_code)}</p></li>
          <li className="commission"><p>{deposit_sum ? toNumberFormat(deposit_sum , 6): "-"}</p></li>
          <li className="referrals"><p>{wagered ? toNumberFormat(wagered, 6): "-"}</p></li>
          <li className="totalCommission">
            {/* <SymbolImg className="symbol-image" symbol={symbol} /> */}
            <p>{commission ? toNumberFormat(commission): "-"}</p>
          </li>
          <li className={`arrow ${active === index ? 'active' : ''}`}></li>
        </ul>
        <div ref={contentEl} className={`accordion-item__body ${active === index ? 'show' : ''}`}
          style={active === index && Object.keys(innerData)?.length ? { height: "auto" } : { height: "0px" }}>
          <div className="item-inner">
            <div className="item-inner__inner">
              <ul className="item-inner__inner--total">
                <li>
                  <span>{t('pages.affiliate.manageCampaign.tableBody.campaignHits')}</span>
                  <p>{total?.campaignHits ? total.campaignHits :""}</p>
                </li>
                <li>
                  <span>{t('pages.affiliate.manageCampaign.tableBody.referrals')}</span>
                  <p>{total?.referrals ? total.referrals : ""}</p>
                </li>
                <li>
                  <span>{t('pages.affiliate.manageCampaign.tableBody.comissionRate')}</span>
                  <p>{total?.comissionRate ? total.comissionRate: ""}%</p>
                </li>
              </ul>
              {active === index && typeof innerList !== "undefined" && innerList?.length ?
                <div className="table-section-list">
                  <div className="table-section-wrapper">
                    <div className="table-section-inner">
                      <div className="table-container item-list">
                        <table className="table-inner">
                          <colgroup>
                            {detailTableHead.map((head, index) => {
                              return <col key={index} className={`${head.includes('cryptos') ? "cryptos" : ""}`} />;
                            })}
                          </colgroup>
                          <thead className="table-inner__head">
                            <tr>
                            {detailTableHead.map((head, index) => {
                              return <th key={index} className={head}>{t(head)}</th>
                            })}
                            </tr>
                          </thead>
                          {innerList.map((item, index) => {
                            const symbol = item?.name.replace(/\(([^)]+)\)/, (match, capture) => {
                              return capture === "Ethereum" ? "(ERC20)" : capture === "Tron" ? "(TRC20)" : match;
                            });
                            return (
                              <tbody className="table-inner__body" key={index}>
                                <tr>
                                  <td><p>{item.date_claimed ? item.date_claimed : '-'}</p></td>
                                  <td><p>{item.name ? symbol : '-'}</p></td>
                                  <td><p>{item.claim_amount ? toNumberFormat(item.claim_amount, 6) : '0.00'}</p></td>
                                  <td><p>{item.rollover_amount ? toNumberFormat(item.rollover_amount, 6) : '0.00'}</p></td>
                                  <td><p>{item.claimable ? 'O' : 'X'}</p></td>
                                  <td><p>{item.claimed ? 'O' : 'X'}</p></td>
                                </tr>
                              </tbody>
                            );
                          })}
                        </table>
                      </div>
                    </div>
                  </div>
                </div> : null}
            </div>
            <div className="actions">
              <div className="actions__link">
                <span>{t('pages.affiliate.manageCampaign.refLink')}</span>
                <CopyItem address={window.location.hostname === "www.acecasino.io" ? `www.${setRefLink(campaign_code)}` : setRefLink(campaign_code)} styled="between" />
              </div>
              <div className="actions__btn">
                {/* <BaseButton onClick={deleteModalOpen} size="small" color="gray" label="pages.affiliate.manageCampaign.delete" /> */}
                <BaseButton onClick={claim} isDisabled={!isClaim} size="small" color="primary" label="pages.affiliate.manageCampaign.claim" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <DeleteCampaignModal open={deleteOpen} handleClose={deleteModalClose} /> */}
    </>
  )
}

function TableBody(props) {
  const { t } = useTranslation();
  const { isChild, campaignList, getUserCampaignList, rewardRatio } = props;
  const [createOpen, setCreateOpen] = useState(false);
  const [active, setActive] = useState(null);
  const [list, setList] = useState([]);

  const toggle = (index) => {
    if (active === index) setActive(null)
    else setActive(index)
  }
  const createModalOpen = () => {
    setCreateOpen(true);
  };
  const createModalClose = () => {
    setCreateOpen(false);
  };

  useEffect(() => {
    if (campaignList?.length) {
      setList(campaignList)
    }
  }, [campaignList])

  return (
    <>
      <div className="campaign-content__body">
        <div className="accordion-table-container manageCampaign">
          <div className="accordion-table-container__inner">
            <ul className="accordion-table-container__head">
              <li className="name"><p>{t('pages.affiliate.manageCampaign.tableHead.name')}</p></li>
              <li className="link"><p>{t('pages.affiliate.manageCampaign.tableHead.link')}</p></li>
              <li className="commission"><p>{t('pages.affiliate.manageCampaign.tableBody.totalDeposits')}</p></li>
              <li className="referrals"><p>{t('pages.affiliate.manageCampaign.tableBody.totalWagered')}</p></li>
              <li className="totalCommission"><p>{t('pages.affiliate.manageCampaign.tableBody.totalCommission')}</p></li>
              <li className="arrow"></li>
            </ul>
            <div className="accordion-table-container__body">
              {list?.length ? list.map((item, index) => {
                return <AccordionItem key={index} active={active} toggle={toggle} item={item} index={index} rewardRatio={rewardRatio} />
              }) : null}
            </div>
            {!list?.length ? <div className="no-list"><img src={NoListLogo} alt="no list" /></div> : null}
          </div>
        </div>
        <BaseButton isDisabled={!!isChild} onClick={createModalOpen} color="white" label="pages.affiliate.manageCampaign.newCampaign" />
      </div>
      <CreateCampaignModal open={createOpen} handleClose={createModalClose} getList={getUserCampaignList} />
    </>
  )
}

// function TableHeader() {
//   const { t } = useTranslation();
//   return (
//     <div className="campaign-content__head">
//       <div className="total-reward">
//         <div className="total-reward__inner">
//           <span className="head-title">{t('pages.affiliate.manageCampaign.head.totalRewardEarned')}</span>
//           <p className="total-reward-data">$895,231.41</p>
//         </div>
//       </div>
//       <div className="line"></div>
//       <ul className="head-inner">
//         <li>
//           <span className="head-title">{t('pages.affiliate.manageCampaign.head.totalReferrals')}</span>
//           <p className="head-data">251 Users</p>
//         </li>
//         <li>
//           <span className="head-title">{t('pages.affiliate.manageCampaign.head.totalWagered')}</span>
//           <p className="head-data">$194,492.95</p>
//         </li>
//         <li>
//           <span className="head-title">{t('pages.affiliate.manageCampaign.head.totalClaimable')}</span>
//           <p className="head-data">$193,395.00</p>
//         </li>
//       </ul>
//     </div>
//   )
// }

export default function ManageCampaign(props) {
  return (
    <div className="campaign-content">
      {/* <TableHeader /> */}
      <TableBody {...props} />
    </div>
  )
}