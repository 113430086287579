import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next'

import useFullscreen from "hooks/useFullscreen";
import { movePath } from 'utils/movePath';
import { setCurrentSymbol } from 'store/modules/user';

import { getStartGameRequestAPI, sessionTerminateAPI } from 'api/pragmatic'

import { useSnackbar } from "notistack";
import GameBalanceSelect from 'components/ui/select/GameBalanceSelect';
import IconButton from 'components/ui/button/IconButton';
import InfinitySwiper from 'components/slider/InfinitySwiper'
import { CircularProgress } from '@mui/material';
import axios from 'axios';

let cancelRequest;

function areObjectsEqual(obj1, obj2) {
  if (!obj1 || !obj2) return false;

  const obj1Keys = Object.keys(obj1);
  const obj2Keys = Object.keys(obj2);

  if (obj1Keys?.length !== obj2Keys?.length) {
    return false;
  }

  for (let key of obj1Keys) {
    if (obj1?.[key] !== obj2?.[key]) {
      return false;
    }
  }

  return true;
}

function GameStackWrapper({gameURL, select, data, gamePlay, loading, demoGamePlay, pathSegments, hasRedeemCode}) {
  const { t } = useTranslation();
  const realDisabled = !gameURL || (!!!hasRedeemCode && (!data.selectList?.length || !select));
  return (
    <div className="game-stack">

      {data.selectList?.length ?
      <div className="game-stack__head">
        <p>{t('pages.games.detail.balance.title')}</p>
        <GameBalanceSelect {...data} />
      </div> :
      <div className="game-stack__not"><p>{t('pages.games.detail.balance.not')}</p></div>}

      {!!hasRedeemCode ? <div className="game-stack__bonus">
        <p>You have a bonus available!</p>
        <span>- You can play the bonus game without needing to select any balance. -</span>
        </div> : null}

      <div className={`game-stack__body ${pathSegments !== "casino" ? '' : 'solo'}`}>
        <IconButton isLoading={loading} isDisabled={realDisabled} onClick={gamePlay} color="primary" iconName="realPlay" label="pages.games.detail.real" size="large" />
        {pathSegments !== "casino" ? <IconButton isLoading={loading} onClick={demoGamePlay} color="white" iconName="funPlay" label="pages.games.detail.fun" size="large" /> : null}
      </div>

    </div>
  )
}

export default function GamePlay() {
  const { gameId } = useParams();
  const location = useLocation();
  const { pathname } = useLocation();
  const { gameData } = location.state || {};
  const nav = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const user = useSelector(state => { return state?.user; });
  const { userInfo, language: lang } = user;
  const { redeem_code } = userInfo
  const pathSegments = pathname?.split('/')[2]; // 상위 path

  const prevSelectRef = useRef();
  const prevRedeemRef = useRef();
  const [play, setPlay] = useState(false);
  const [playLoading, setPlayLoading] = useState(false);
  const [gameURL, setGameURL] = useState("")
  const [loading, setLoading] = useState(false)
  const [select, setSelect] = useState(null);
  const [selectList, setSelectList] = useState(null);
  const [hasRedeemCode, setHasRedeemCode] = useState(null);
  const { element, triggerFull, exitFull, isFullscreen } = useFullscreen();
  const data = { select, setSelect, selectList, id: "game-select", isDisabled: !!hasRedeemCode }

  const reflash = async () => {
    setPlay(false);
    setGameURL("");
    const email = userInfo?.email;
    await sessionTerminateAPI(email)
    getGameURL();
  }
  useEffect(() => {
    setSelect(null)
    if (gameId === "vs20sugarrush" && redeem_code && !redeem_code?.includes("__END__")) {
      setHasRedeemCode(redeem_code)
      } else {
      setHasRedeemCode(false)
      reflash()
    }
  }, [gameId, redeem_code])

  useEffect(() => {
    if (!play) {
      const selectList = [];
      for (const [key, item] of Object.entries(userInfo?.currency)) {
        userInfo?.balance?.forEach(itm => {
          if (key === itm?.symbol && itm.total-0 > 0) {
            selectList.push(item)
          }
        })
      }
      const selectSymbol = selectList[0] ?? {};
      setSelectList(selectList)
      setSelect(selectSymbol)
    }
  }, [userInfo.balance])


  useEffect(() => {
    if (!select || hasRedeemCode === null) return;

    const prevSelect = prevSelectRef.current;
    const prevRedeem = prevRedeemRef.current;

    const handleRedeemCodeInit = () => {
      if (hasRedeemCode && prevRedeem !== hasRedeemCode) {
        getGameURL();
        prevRedeemRef.current = hasRedeemCode;
      }
    };

    const handleSelectInit = () => {
      if (select && !areObjectsEqual(prevSelect, select)) {
        if (play) setPlay(false);
        if (gameURL) setGameURL("");
        getGameURL();
        prevSelectRef.current = select;
      }
    };

    handleRedeemCodeInit();
    if (!hasRedeemCode) {
      handleSelectInit();
    }
  }, [select, hasRedeemCode])

  /* 게임 실행 URL 생성 */
  const getGameURL = async (playMode) => {
    const email = userInfo?.email;
    const currency = (!!hasRedeemCode && !playMode) ? "USDT(Tron)" : select?.symbol;

    if (!userInfo?.email || (!currency && !playMode)) return;

    setLoading(true);
    if (cancelRequest) {
      cancelRequest();
    }
    const source = axios.CancelToken.source()
    cancelRequest = source.cancel;
    try {
      const res = await getStartGameRequestAPI(gameId, currency, email, playMode, source.token)
      setGameURL(res)
      setLoading(false)
      if (playMode) {
        setPlay(true)
        setPlayLoading(false)
      }
    } catch (e) {
      console.log("getGameURL e: ", e);
      setLoading(false);
    }
  }

  /* 게임 세션 종료 */
  const sessionTerminate = async () => {
    if (!userInfo?.email) return;
    const email = userInfo?.email;
    await sessionTerminateAPI(email)
    .then(() => {
      movePath(nav, lang, `/${pathSegments}`)
      enqueueSnackbar({ msg: "pages.games.detail.expired", variant: "info", action: () => {closeSnackbar()} });
    })
    .catch(e => {
      console.log("sessionTerminateAPI e: ", e)
    })
  }

  /* 데모게임 URL 생성 후 자동 플레이 */
  const demoGamePlay = async () => {
    if (gameURL) setGameURL("");
    setPlayLoading(true)
    try {
      const email = userInfo?.email;
      await sessionTerminateAPI(email)
      getGameURL("DEMO");
    }
    catch(e) {
      setPlayLoading(false)
      console.log("demoGamePlay e: ", e)
    }
  }

  /* 리얼게임 플레이 후 stroe symbol 셋팅 */
  const gamePlay = () => {
    if (!gameURL) return;
    try {
      setPlay(true)
      if (Object.keys(select)?.length) dispatch(setCurrentSymbol(select))
    }
    catch(e) {
      console.log("e: ", e)
    }
  }

  const gameStackData = { pathSegments, select, data, gameURL, gamePlay, loading, demoGamePlay, hasRedeemCode }

  return (
    <div className="gameplay-wrapper">
      <div className="game-header">
        <div className="game-header__title">
          <p>{gameData?.gameName ? gameData.gameName : ""}</p>
        </div>
        <div className="game-header__action">
          <button disabled={!play} onClick={triggerFull} className="fullpage-btn"></button>
          <button disabled={!play} onClick={sessionTerminate} className="close-btn"></button>
        </div>
      </div>
      <div className="game-container">
        {playLoading && <div className="loading"><CircularProgress color="inherit" size={50} /></div>}
        {!play && <div className="overlay">
          <GameStackWrapper {...gameStackData} />
        </div>}
        <div className="frame-game" ref={element}>
          <iframe title="game-play"
            className="frame-game"
            src={gameURL}
            width="100%"
            height="100%"
            webkitallowfullscreen=""
            allow="autoplay; fullscreen"
            mozallowfullscreen="">
          </iframe>
          {isFullscreen && <button onClick={exitFull} className="game-fullpage-close-btn"></button>}
        </div>
      </div>
      <div className="game-footer-content">
        <InfinitySwiper {...gameData} />
      </div>
    </div>
  )
}