import React, { useState, useEffect, cloneElement } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectModal, closeModal, setTwoFactorModal } from 'store/modules/components';
import Dialog from '@mui/material/Dialog';
import DepositLayout from '../wallet/DepositLayout';
import WithdrawLayout from '../wallet/WithdrawLayout';
import { useTranslation } from 'react-i18next'
// import BaseButton from 'components/ui/button/BaseButton';
// import { CircularProgress } from '@mui/material';
// import BuyCryptoLayout from '../wallet/BuyCryptoLayout';

const tabs = [
  {
    id: 'deposit',
    component: <DepositLayout />
  },
  {
    id: 'withdraw',
    component: <WithdrawLayout />
  },
  // {
  //   id: 'crypto',
  //   component: <BuyCryptoLayout />
  // },
]

function TabsHeader(props) {
  const { t } = useTranslation();
  const { tabs, activeTab, changeTab } = props;
  return (
    <div className="tabs-wrapper two-layout">
      <div className="tabs-header">
      {tabs.map((item, index) => {
        return <button key={index}
          className={`tab-button ${activeTab.id === item.id ? `active` : ''}`}
          onClick={() => changeTab(item)} disabled={!item.component}>
            {t(`component.modal.wallet.${item.id}.title`)}
          </button>
        })}
      </div>
    </div>
  )
}


function TabsBody(props) {
  const { activeTab } = props;
  const { component } = activeTab;
  return (
    <div className="content-detail-wrapper tabs-layout">
      {cloneElement(component)}
      {/* {(hasBalanceList.length || allBalanceList.length) ? cloneElement(component, propData) : <div className="loading-bar"><CircularProgress size={50} color="inherit" /></div>} */}
    </div>
  )
}
function TabsLayout() {
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const changeTab = (id) => {
    setActiveTab(id);
  };
  return (
    <>
      <TabsHeader tabs={tabs} activeTab={activeTab} changeTab={changeTab} />
      <TabsBody activeTab={activeTab} />
    </>
  );
}

export default function WalletModal() {
  const { t } = useTranslation();
  const { modalType, isOpen } = useSelector(selectModal);
  // const hasOtp = useSelector(state => { return state?.user.hasOtp; });
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closeModal());
  };
  // const twoFactorModalOpen = () => {
  //   dispatch(setTwoFactorModal(true));
  // };
  return (
    <>
      <Dialog
        open={modalType === "Wallet" && isOpen}
        onClose={handleClose}
        className="base-dialog-wrapper content-dialog-wrapper"
      >
        <div className="base-dialog-inner">
          <div className="base-dialog-header">
            <p className="base-dialog-header__title">{t('component.modal.wallet.title')}</p>
            <button onClick={()=> handleClose()} className="close-btn"></button>
          </div>
          <div className="base-dialog-body">
            <TabsLayout />
          </div>
        </div>
        {/* {!hasOtp &&
        <div className="base-dialog-footer">
          <div className="base-dialog-footer-inner">
            <div className="info-desc">
              <p dangerouslySetInnerHTML={{ __html: t('component.modal.footer2fa.infoMsg') }}></p>
            </div>
            <BaseButton onClick={twoFactorModalOpen} color="white" label="component.modal.footer2fa.btn" size="small" />
          </div>
        </div>} */}
      </Dialog>
    </>
  )
}