import { apiRequest } from 'utils/axios';


/**
 * 게임 리스트 조회 API
 */
// export const getGameListAPI = async (category) => {
//   return new Promise((resolve, reject) => {
//     const url = `/pragmatic/get-casino-games?category=${category}`;
//     apiClient.get(url)
//     .then(res => {
//       resolve(res.data);
//     })
//     .catch(e => {
//       reject(e);
//     })
//   })
// }

/**
 * 게임 리스트 조회 API
 * @param {string} gameType 생략가능 (전체): Live casino, lg, Slot, vs : 4가지 입력 받을수 있으며 Live casino == lg, Slot == vs 와 같습니다.
 * @param {string} search 생략가능(전체): 검색 키워드 입력 대소문자 무시
 * @param {string} sort 생략가능(game_name): game_name 만 가능합니다.
 * @param {string} sortOrder 생략가능(ASC) DESC
 * @param {number} limit 생략가능(30)
 * @param {number} page 생략가능(1)
 */
export const getGameListAPI = async (gameType, search, page, limit, sortOrder, sort) => {
  try {
    let url = `/pragmatic/get-casino-game-search?gameType=${gameType}`;
    if (search) {
      url += `&search=${search}`;
    }
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (sortOrder) {
      url += `&sortOrder=${sortOrder}`;
    }
    if (limit) {
      url += `&limit=${limit}`;
    }
    if (page) {
      url += `&page=${page}`;
    }
    const result = await apiRequest({
      method: 'get',
      url,
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}


/**
 * 게임 실행 링크 조회 API
 *
 * @param {string} symbol game list 에서 받은 gameID (vs20olympgate)
 * @param {string} currency Real 게임일 시 입력
 * @param {string} email 유저 이메일
 * @param {string} playMode DEMO
 */
export const getStartGameRequestAPI = async (symbol, currency, email, playMode, cancelToken) => {
  try {
    let url = `/pragmatic/StartGameRequest?symbol=${symbol}&email=${email}`
    if (playMode) {
      url += `&playMode=${playMode}`;
    }
    if (currency) {
      url += `&currency=${currency}`;
    }
    const result = await apiRequest({
      method: 'get',
      url,
    }, cancelToken);
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * 게임 세션 종료 API
 *
 * @param {string} email 유저 이메일
 */
export const sessionTerminateAPI = async (email) => {
  try {
    const result = await apiRequest({
      method: 'post',
      url: `/pragmatic/sessionTerminate`,
      data: { email }
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}


/**
 * 리딤 코드 등록
 */
export const postRedeemCodeAPI = async (email, code) => {
  try {
    const result = await apiRequest({
      method: 'post',
      url: `/pragmatic/redeemCode`,
      data: { email, code }
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}